import { view } from "@risingstack/react-easy-state";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer"
import { useCallback, useEffect, useState } from "react";
import request from "../../../helpers/request";
import endpoints from "../../../helpers/endpoints";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../components/pagination/pagination";
import "./propertiesPerLandlord.scss"

const localised = Intl.NumberFormat("en-GB");

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const columnHelper = createColumnHelper()

const columns = [
  columnHelper.accessor('landlord_name', {
    header: () => <div style={{textAlign: "center"}}>Landlord Name</div>,
    enableSorting: false,
  }),
  columnHelper.accessor('date_updated', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Last Update Date" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{getValue()}</div>
  }),
  columnHelper.accessor('total_number_of_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Total Properties" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
  columnHelper.accessor('total_no_of_active_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Active" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
  columnHelper.accessor('total_no_of_archived_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Archived" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
  columnHelper.accessor('total_number_of_draft_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Draft" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
  columnHelper.accessor('number_of_distinct_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Total Distinct Properties" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
  columnHelper.accessor('no_of_distinct_active_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Distinct Active" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
  columnHelper.accessor('no_of_distinct_archived_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Distinct Archive" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
  columnHelper.accessor('no_of_distinct_draft_properties', {
    header: ({ column, table }) => <SortableHeader column={column} table={table} title="Distinct Draft" />,
    cell: ({ getValue }) => <div style={{textAlign: "right"}}>{localised.format(getValue())}</div>
  }),
]

function SortingIndicator({direction, visible}) {
  return (
    <i style={{ opacity: visible ? '100%' : '0', paddingLeft: 5, verticalAlign: 'middle' }} className={"fa-solid fa-sort-" + (direction === "up" ? "up" : "down")}></i>       
  )
}

function SortableHeader({ column, table, title, align = "right" }) {
  const sorting = table.getState().sorting;
  const isSorted = sorting.find(sort => sort.id === column.id);

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <SortingIndicator direction={isSorted?.desc ? "down" : "up"} visible={!!isSorted} />
      <div style={{ textAlign: align }}>
        {title}
      </div>
    </div>
  );
}

function getFormattedDate() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');  // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}_${month}_${day}__${hours}_${minutes}_${seconds}`;
}

export default view(withRouter(function PropertiesPerLandlord() {
  const [data, setData] = useState([])
  const [page, setPage] = useState({
    page: 1,
    last_page: null
  });
  const [sorting, setSorting] = useState([
    {
      id: 'total_number_of_properties',
      desc: true,
    }
  ])
  const [searchQuery, setSearchQuery] = useState('')
  const [downloading, setDownloading] = useState(false)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting,
    },
    onSortingChange: (newSorting) => {
      const currentSorting = newSorting(); // Call the function to get the current sorting state
      const clickedColumn = currentSorting[0]?.id;

      let updatedSorting;
      if (!sorting.length || sorting[0].id !== clickedColumn) {
        // No sorting or different column: start with ascending
        updatedSorting = [{ id: clickedColumn, desc: false }];
      } else if (sorting[0].desc === false) {
        // Currently ascending: switch to descending
        updatedSorting = [{ id: clickedColumn, desc: true }];
      } else {
        // Currently descending: remove sorting
        updatedSorting = [];
      }

      setSorting(updatedSorting);
    },
  })

  let timeout
  const loadPage = useCallback((page = 1) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      request(true).get(endpoints.PROPERTIES_PER_LANDLORD_SUMMARY, {
        params: {
          page,
          sorting,
          search: searchQuery,
        }
      })
        .then(e => {
          setData(e.data.data.data)
          setPage({
            page: e.data.data.current_page,
            last_page: e.data.data.last_page
          })
        })
    }, 300)
  }, [sorting, searchQuery])

  useEffect(() => {
    loadPage()
  }, [loadPage])

  const exportToExcel = useCallback(() => {
    setDownloading(true)
    request(true).get(endpoints.PROPERTIES_PER_LANDLORD_DOWNLOAD, {
      params: {
        page,
        sorting,
        search: searchQuery,
      }
    }).then(response => {
        const timestamp = getFormattedDate();
        const filename = `properties_per_landlord-${timestamp}.csv`
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloading(false)
      })
  }, [sorting, searchQuery])

  return (
    <GenericPageContainer
      containerWidth="92vw"
      title="Properties Per Landlord"
      titleIconClass={"fak fa-id-house-icon fa-2x colour-primary"}
    >
      <div className="ppl" style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', justifyContent: "space-between", maxWidth: "100%" }}>
          <input type="text" placeholder="Search..." className="input" onChange={e => setSearchQuery(e.target.value)} value={searchQuery} />
          <div style={{ marginInline: 10 }}>
            {downloading ? 
              <i style={{ color: "var(--primary)"}} className={"fa-solid fa-check"}></i>       
              :
              <img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px' }} onClick={ () => exportToExcel()}></img>
            }
          </div>
        </div>
        <div style={{ display: 'grid', gap: 10, paddingBottom: "15px" }}>
          <table className='table borders squish smaller-text left' style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} style={{ borderCollapse: "collapse" }}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} onClick={header.column.getToggleSortingHandler()} style={{ borderInline: "1px solid white" }} colSpan={header.id === "landlord_name" ? 2 : 1}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} colSpan={cell.column.id === "landlord_name" ? 2 : 1}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination page={ page.page } pages={ page.last_page } pageChange={ loadPage } style={{margin: 'auto'}}/>
    </GenericPageContainer>
  )
}))
