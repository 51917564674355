import React from 'react'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react'
import Select from 'react-select'
import request from '../../helpers/request';

export default function ProductSelect({ onChange, product }) {
  const [products, setProducts] = useState([]);
  const productOptions = useMemo(() => {
    return products.map((product) => {
      const labelParts = product.product_key.split('_')
      const labelPartsUpperCase = labelParts.map(label => label.charAt(0).toUpperCase() + label.slice(1))
      const cleanLabel = labelPartsUpperCase.join(' ')
      return {
        label: cleanLabel,
        value: product.id
      }
    })
  }, [products]);
  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: '100%',
      width: '20vw'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    }),
  };

  useEffect(() => {
    request(true).get('/real-products').then(data => {
      if (data.status === 200) {
        setProducts(data.data.sort((a, b) => a.product_key.localeCompare(b.product_key)))
      }
    })
  }, [])

  return (
    <Select placeholder="Real Product" value={productOptions?.find(option => option?.value === product)} isClearable onChange={onChange} options={productOptions} isLoading={products.length === 0} styles={customStyles} />
  )
}
