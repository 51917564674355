import React, { Component } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import Pagination from '../../components/pagination/pagination';
import Modal from 'react-modal';

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';
import { Link } from 'react-router-dom';

export default class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            pagination: {
                page: 1,
                last_page: null
            },
            modalIsOpen: false,
            company: {
                name: '',
                subdomain: '',
            },
            errors: {}
        }
    }

    updatePage = (page) => {
        this.loadPage(page);
    }

    loadPage = (page = 1) => {
        request(true).get(endpoints.ADMIN_COMPANIES_INDEX + '?page=' + page).then(e => {
            //console.log(e.data);
            this.setState({
                companies: e.data.data,
                pagination: {
                    page: e.data.current_page,
                    last_page: e.data.last_page
                }
            });
        }).catch(e => {
            //console.log(e.response);
        });
    }

    componentDidMount() {
        this.loadPage();
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false
        });
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true
        });
    }

    updateFormValue = (e) => {
        this.setState({
            company: {
                ...this.state.company,
                [e.target.name]: e.target.value ? e.target.value : undefined
            }
        })
    }

    formSubmit = () => {
        request(true).post(endpoints.ADMIN_COMPANIES_INDEX, this.state.company).then(r => {
            this.loadPage(1);
            this.setState({
                company: {
                    name: '',
                    subdomain: '',
                }
            });
        }).catch(r => {
            //console.log(r.response);
            this.setState({ errors: r.response.data.errors });
        });
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                <Container>
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Companies</p>
                        <button onClick={this.openModal} style={{ marginLeft: 'auto' }} className="button background-primary colour-white">Add A Company</button>
                    </div>
                </Container>
                <Container>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Company ID</th>
                                <th>Company Name</th>
                                <th>Subdomain</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.companies.map(company => {
                                return (
                                    <tr key={company.id}>
                                        <td>{ company.id }</td>
                                        <td>{ company.name }</td>
                                        <td>{ company.subdomain }</td>
                                        <td className="actions">
                                            <Link to={ "/admin/portal/companies/" + company.id }><i class="fa fa-search" aria-hidden="true"></i></Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Pagination page={this.state.pagination.page} pages={this.state.pagination.last_page} pageChange={this.updatePage}/>
                </Container>
                <Modal
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                    className="card"
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Add A Company"
                    appElement={document.getElementById('admin-page')}
                >
                    <div className="grid grid-gap-15">
                        <div className="form-row">
                            <label htmlFor="name">Company Name:</label>
                            <input onChange={this.updateFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.company.name} required/>
                            { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                return (
                                    <p key={index}>
                                        { value }
                                    </p>
                                )
                            }) : <></>}
                        </div>
                        <div className="form-row">
                            <label htmlFor="subdomain">Company Subdomain:</label>
                            <input onChange={this.updateFormValue} name="subdomain" placeholder="Subdomain" className="input" type="text" value={this.state.company.subdomain}/>
                            { this.state.errors.subdomain ? this.state.errors.subdomain.map((value, index) => {
                                return (
                                    <p key={index}>
                                        { value }
                                    </p>
                                )
                            }) : <></>}
                        </div>
                        <button onClick={this.formSubmit} className="button background-primary colour-white">Create</button>
                    </div>
                </Modal>
                <Footer />
            </div>
        )
    }
}
