import React, { Component } from 'react'
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'
import Card from '../components/card/card'
import HelpVideo from '../components/help-video/helpVideo'

export default class ActivatePage extends Component {
    constructor(props) {
        super(props);

        this.params = new URLSearchParams(this.props.location.search);

        this.state = {
            form: {
                action: "",
                signature: "",
            },
            errors: {

            }
        }
    }

    updateFormValue = (e) => {
        let passwordStrong = this.state.passwordStrong;
        let passwordMatch = this.state.passwordMatch;

        if (e.target.name === 'password') {
            if (e.target.value.match(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/)) {
                passwordStrong = true;
            } else {
                passwordStrong = false;
            }

            passwordMatch = e.target.value === this.state.form.confirmPassword && passwordStrong;
        }

        if (e.target.name === 'confirmPassword') {
            passwordMatch = (e.target.value === this.state.form.password) && passwordStrong;
        }

        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value ? e.target.value : undefined
            },
            passwordStrong: passwordStrong,
            passwordMatch: passwordMatch
        });
    }

    formSubmit = (e) => {
        e.preventDefault();
        if (!(this.state.form.password === this.state.form.confirmPassword)) {
            return this.setState({
                errors: {
                    password: ['Passwords do not match.']
                }
            });
        }
        request(false).post(endpoints.ACTIVATE_POST, this.state.form).then(r => {
            this.props.history.push('/login');
        }).catch(r => {
            this.setState({
                errors: r.response.data.errors
            });
        });
    }

    componentDidMount() {
        if (sessionStorage.getItem('api-path') === null) {
            this.handleWait();
        } else {
            this.handleReady();
        }
    }

    handleWait = () => {
        setTimeout(() => {
            if (sessionStorage.getItem('api-path') === null) {
                this.handleWait();
            } else {
                this.handleReady();
            }
        }, 250);
    }


    handleReady = () => {
        const queryString = window.location.href.substring(window.location.href.indexOf('?') + 1);
        const signature = queryString.substring(queryString.indexOf('signature') + 10);
        //console.log(url);
        request(false).post(endpoints.ACTIVATE, {
            action: this.params.get('action'),
            signature: signature
        }).then(r => {
            if (r.data.valid) {
                this.setState({
                    form: {
                        action: this.params.get('action'),
                        signature: signature,
                    }
                })
            } else {
                this.props.history.push('/login');
            }
        }).catch(r => {
            this.props.history.push('/login');
        })
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                <Container>
                <Card>
                        <div className="">
                            <p className="font-weight-600 font-size-2 font-align-center">Activate</p>
                        </div>
                        <p>Please enter a password for your account</p>
                        <p className="error">{ this.state.error }</p>
                        <form onSubmit={this.formSubmit} className="grid grid-gap-15">
                            { this.state.errors.password ? this.state.errors.password.map((value, index) => {
                                return (
                                    <p key={index}>
                                        { value }
                                    </p>
                                )
                            }) : <></>}
                            <input onChange={this.updateFormValue} name="password" placeholder="Password" className={`input ${this.state.passwordStrong ? 'color-green' : ''}`} type="password" required/>
                            <small>Passwords must be at least 12 characters long and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</small>
                            <input onChange={this.updateFormValue} name="confirmPassword" placeholder="Confirm Password" className={`input ${this.state.passwordMatch ? 'color-green' : ''}`} type="password" required/>
                            <button className="button background-primary colour-white">Activate</button>
                        </form>
                    </Card>
                    <HelpVideo video={sessionStorage.getItem('login-video-path')}/>
                </Container>
                <Footer />
            </div>
        )
    }
}
